import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login';


const routes: Routes = [
  
  { path: '', component: LoginComponent },
  // { path: 'iasgcon2025', loadChildren: () => import('./forgotpassword/forgotpassword.module').then( (m) => m.ForgotPasswordModule) },
  { path: 'account', loadChildren: () => import('./user/profileupdate/account.module').then( (m) => m.AccountModule) },
  { path: 'mytasks', loadChildren: () => import('./home/task.module').then( (m) => m.TaskModule) },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then( (m) => m.AdminModule) },
  { path: 'abstract', loadChildren: () => import('./workflow/workflow/workflow.module').then( (m) => m.WorkflowModule) },
]; 

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
