import {
  Injectable,
  Inject,
  EventEmitter
} from '@angular/core';
import {
  RestService
} from '../core/rest.service';

import {
  AppConfig
} from '../../config/app.config';
import { map } from 'rxjs/operators';

@Injectable()
export class UserService {

  constructor(
    private restService: RestService,) {
  }
 /**
   * 
   * @param passwordInfo 
   * @returns 
   */
 public changeUserPassword(userId,passwordInfo) {
  return this.restService.put(`${AppConfig.BASE_URL_V09}/users/changePassword`, passwordInfo).pipe(map((res: any) => res));
}
  /**
  * update logined user details
  * @param userInfo 
  * @param id 
  * @returns 
  */
  public updateProfile(userInfo) {
    return this.restService.put(`${AppConfig.BASE_URL_V1}/profile/`, userInfo,null).pipe(map((res: any) => res));
  }
  public getMyProfile(){
    return this.restService.get(`${AppConfig.BASE_URL_V1}/profile`, true).pipe(map((res: any) => res));
  }
  public getUsers(count) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/user', { page: count }, true).pipe(map((res: any) => res));
  }

  public getUser(id) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/user/' + id, true).pipe(map((res: any) => res));
  }
  public changePassword(id, password) {
    return this.restService.put(AppConfig.BASE_URL + '/editaware/user/credentials/' + id, password).pipe(map((res: any) => res));
  }
  public enableUser(id, enable) {
    let data = {
      enabled: enable
    };
    return this.restService.put(AppConfig.BASE_URL + '/editaware/user/credentials/' + id, data, true).pipe(map((res: any) => res));
  }
  public addUser(values) {
    const data = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      confirmPassword: values.confirmPassword
    };
    return this.restService.post(AppConfig.BASE_URL + '/editaware/user/iasg/register/abstract/iasgcon-2024', data).pipe(map((res: any) => res));
  }
  public updateUser(values, id) {
    let data = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName,
      mobile: values.mobile,
      title: values.title,
      jobtitle: values.jobTitle,
      location: values.department,
      organization: values.organization,
    };
    return this.restService.put(AppConfig.BASE_URL + '/editaware/user/' + id + '?eventName=iasgcon-2024', data).pipe(map((res: any) => res));
  }
  public searchUser(search, count) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/user', { key: search, page: count }, true).pipe(map((res: any) => res));
  }
  public searchUserbyName(search) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/user/searchUsersByName/' + search, true).pipe(map((res: any) => res));
  }
  public registerUser(values) {
    const data = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      title: values.title,
      middleName: values.middleName,
      mobile: values.mobile,
      jobtitle: values.jobTitle,
      organization: values.organization,
      location: values.department,
      confirmPassword: values.confirmPassword
    };
    return this.restService.post(AppConfig.BASE_URL + '/editaware/user/register', data).pipe(map((res: any) => res));
  }


  public deleteUser(id) {
    return this.restService.delete(AppConfig.BASE_URL + '/editaware/user/' + id, null).pipe(map((res: any) => res));
  }
  public forgotPassword(userid, link) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/user/initiateResetPassword', { userId: userid, resetPasswordUrl: link }, null).pipe(map((res: any) => res));
  }
  public resetPasswordCode(code) {
    return this.restService.get(AppConfig.BASE_URL + '/editaware/user/verifyResetPasswordCode', { code: code }, true).pipe(map((res: any) => res));
  }
  public resetPassword(values) {
    const data = {
      password: values.password,
      confirmPassword: values.confirmPassword,
    };
    return this.restService.post(AppConfig.BASE_URL + '/editaware/user/resetpassword/' + values.userId, data).pipe(map((res: any) => res));
  }
}
