import { RestService } from './../../core/rest.service';
import { Component, OnInit, AfterViewInit, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';


import { AppConfig } from '../../../config/app.config';
import { MessageService } from 'src/core/message/message.service';
import { CommunicationService } from 'src/services/communication.service';
import { AbstractService } from 'src/services/abstract.service';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { validateAllFormFields } from 'src/utils/validateform';
declare const gapi: any;
@Component({
  selector: 'login',
  styleUrls: ['./login.component.css'],
  templateUrl: './login.component.html',
  providers: [AuthService, RestService]
})

export class LoginComponent implements OnInit, OnDestroy {
  public localState = { value: '' };
  formGroup: FormGroup;

  // TO MAKE RESPONSIVE
  isCollapsed: boolean = true;
  lastUrl: any;
  public auth2: any;
  googleEmail: any;
  googleToken: any;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  loginRequired: string;
  loginMinLength: string;
  loginMaxLength: string;
  loginEmailInvalid: string;
  passwordRequired: string;
  passwordMaxLength: string;
  sendOtpFormGroup: FormGroup;
  oneTimePasswordFormGroup: FormGroup;
  isShowOtpFormGroup: boolean = false;
  isUserNotRegistered: boolean = false;
  isRegistrationRequired: boolean = false;
  registerCheckboxValue: boolean = false;
  @ViewChild('registerModal', { static: false }) registerModal: ModalDirective;
  @ViewChild('otpModal', { static: false }) otpModal: ModalDirective;
  
  modalRef: any;
  conferenceShortName: string;
  eventShortName: string;
  isShowAlert = false;
  verification;
  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router, public modalService: BsModalService,
    public ngZone: NgZone, public cookieService: CookieService, public messagingService: MessageService,
    public translate: TranslateService, public communicationService: CommunicationService, public abstractService: AbstractService,
    private CookieService: CookieService) {
    // if (this.cookieService.get('userId')) {
    //   this.router.navigate(['/home']);
    // } @TODO

    this.lastUrl = this.cookieService.get('lastUrl');
    // this.deleteAllCookies();
    localStorage.clear()
    sessionStorage.clear();
    this.conferenceShortName = this.route.snapshot.queryParamMap.get('conference') ? this.route.snapshot.queryParamMap.get('conference') : 'IEEE-9';
    this.eventShortName = this.route.snapshot.queryParamMap.get('event') ? this.route.snapshot.queryParamMap.get('event') : 'event3';
  }
  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }
  public ngOnInit() {
    this.formGroup = new FormGroup({
      Email: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      ]),
      Password: new FormControl('', [
        Validators.required,
        Validators.maxLength(30)
      ])
    });
    this.sendOtpFormGroup = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])
    });
    this.oneTimePasswordFormGroup = new FormGroup({
      otpContent: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(50)
      ])
    });
  }


  onSubmit(values) {
    if (this.formGroup.valid) {
      const data = {
        email: values.Email.toLowerCase(),
        password: values.Password
      };
      this.authService.loginUsingEmail(data).subscribe((res) => {
        if(res.body.data) {
          const helper = new JwtHelperService();
          const decodedToken = helper.decodeToken(res.body.data.token);
          localStorage.setItem('token', res.body.data.token);
          localStorage.setItem('userId', decodedToken.data.id);
          localStorage.setItem('adminAuthority', decodedToken.data.isAdmin);
          localStorage.setItem('userName', decodedToken.data.firstName);
          localStorage.setItem('displayName',`${decodedToken.data.displayName}` );
          this.isShowAlert = false;
          if (decodedToken.data.isAdmin) {
            this.redirectTo();
          } else {
            if (decodedToken.data.roles.length !== 0) {
              const isChiefEditor = decodedToken.data.roles.find(role => (role == `GROUP_jrnl_${AppConfig.JOURNAL_ID}_chiefeditor`) ? true : false);
              const isEditor = decodedToken.data.roles.find(role => (role == `GROUP_jrnl_${AppConfig.JOURNAL_ID}_editor`) ? true : false);
              const isReviewer = decodedToken.data.roles.find(role => (role == `GROUP_jrnl_${AppConfig.JOURNAL_ID}_reviewer`) ? true : false);
              const isAdministrator = decodedToken.data.roles.find(role => (role == `GROUP_jrnl_${AppConfig.JOURNAL_ID}_administrator`) ? true : false);
              const isJuryMember = decodedToken.data.roles.find(role => (role == `GROUP_jrnl_${AppConfig.JOURNAL_ID}_jury_member`) ? true : false);
              if (isChiefEditor  || isAdministrator || isReviewer || isEditor) {
                if (this.lastUrl) {
                  this.router.navigateByUrl(this.lastUrl);
                  this.cookieService.delete('lastUrl');
                } else {
                  if(isReviewer) {
                    this.router.navigate(['/mytasks']);
                  } else {
                    this.router.navigate(['/admin/submissions']);
                  }   
                }
              } else {
                this.isShowAlert = true;
                this.messagingService.notify('error', 'Error', 'You are not authorized');
              }
            }
          }
        } (err) => {
        }
      });
    } else {
      validateAllFormFields(this.formGroup);
    }
  }
  public closeAlert() {
    // this.alert.nativeElement.classList.remove('show');
    this.isShowAlert = false;
  }

  onReset() {
    this.formGroup.reset();
  }

  goToForgotPassword() {
    this.router.navigate(['/forgotpassword']);
  }

  setCookieAndNavigate(userId, adminAuthority, userName) {
    this.communicationService.setUserId(userId);
    this.communicationService.setAdminAuthority(adminAuthority);
    this.communicationService.setUserName(userName);
  }
  redirectTo() {
    if (this.lastUrl) {
      this.router.navigateByUrl(this.lastUrl);
      this.cookieService.delete('lastUrl');
    } else {
      this.router.navigate(['/admin/submissions']);
    }
  }




  ngOnDestroy() {
    if (this.modalRef !== undefined) {
      this.modalRef.hide();
    }
  }

  goToRegister() {
    this.router.navigate(['/register']);
  }
  openOTPModel() {
    this.otpModal.config = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
    };
    this.otpModal.show();
  }
  public onHideOtpModal() {
    this.oneTimePasswordFormGroup.reset();
    this.sendOtpFormGroup.reset();
    this.isShowOtpFormGroup = false;
    this.isUserNotRegistered = false;
    this.registerCheckboxValue = false;
    this.isRegistrationRequired = false;
    this.otpModal.hide();
  }
  onSubmitSendOtpFormGroup(value) {
    if (this.sendOtpFormGroup.valid) {
      const email = value.email.toLowerCase();
      this.authService.userAlreadyExists(email).subscribe((res) => {
        if (res.body.data.isExists == true) {
          this.isUserNotRegistered = false;
          const info = {
            email: value.email.toLowerCase(),
          };
          this.authService.sendotp(info).subscribe((res) => {
            if (res.body.data) {
            this.verification = res.body.data.verification
            this.isShowOtpFormGroup = true;
            }
          });
        } else {
          this.isUserNotRegistered = true;
        }
      });
    }else {
      validateAllFormFields(this.sendOtpFormGroup);
    }
  }
  public onSubmitOtpFormGroup(value) {
    if (this.oneTimePasswordFormGroup.valid) {
      const otpInfo = {
        email: this.sendOtpFormGroup.value.email.toLowerCase(),
        otp: value.otpContent,
        verification: this.verification
      };
      this.authService.loginotp(otpInfo).subscribe((res) => {
        if (res.body.data) {
          this.onHideOtpModal();
          const helper = new JwtHelperService();
          const decodedToken = helper.decodeToken(res.body.data.token);
          localStorage.setItem('token', res.body.data.token);
          localStorage.setItem('userId', decodedToken.data.id);
          localStorage.setItem('adminAuthority', decodedToken.data.isAdmin);
          localStorage.setItem('userName', decodedToken.data.firstName);
          localStorage.setItem('displayName',`${decodedToken.data.displayName}` );
          this.isShowAlert = false;
          if (decodedToken.data.isAdmin) {
            this.redirectTo();
          } else {
            if (decodedToken.data.roles.length !== 0) {
              const isChiefEditor = decodedToken.data.roles.find(role => (role == `GROUP_jrnl_${AppConfig.JOURNAL_ID}_chiefeditor`) ? true : false);
              const isEditor = decodedToken.data.roles.find(role => (role == `GROUP_jrnl_${AppConfig.JOURNAL_ID}_editor`) ? true : false);
              const isReviewer = decodedToken.data.roles.find(role => (role == `GROUP_jrnl_${AppConfig.JOURNAL_ID}_reviewer`) ? true : false);
              const isAdministrator = decodedToken.data.roles.find(role => (role == `GROUP_jrnl_${AppConfig.JOURNAL_ID}_administrator`) ? true : false);
              const isJuryMember = decodedToken.data.roles.find(role => (role == `GROUP_jrnl_${AppConfig.JOURNAL_ID}_jury_member`) ? true : false);
              if (isChiefEditor  || isAdministrator || isReviewer) {
                if (this.lastUrl) {
                  this.router.navigateByUrl(this.lastUrl);
                  this.cookieService.delete('lastUrl');
                } else {
                  if(isReviewer) {
                    this.router.navigate(['/mytasks']);
                  } else {
                    this.router.navigate(['/admin/submissions']);
                  }   
                }
              } else {
                this.isShowAlert = true;
                this.messagingService.notify('error', 'Error', 'You are not authorized');
              }
            }
          }
        }
      });
    } else {
      validateAllFormFields(this.oneTimePasswordFormGroup);
    }
  }
}
